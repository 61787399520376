import { ApolloProvider } from '@apollo/client'
import { FC, useMemo } from 'react'
import { createClient } from './client'

const host = process.env.AUTH_PF_CLINIC_API_HOST!

export const AppApolloProvider: FC<{}> = ({ children }) => {
  const client = useMemo(() => createClient(host), [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
