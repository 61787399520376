import { createContext, FC, useContext, useMemo, useState } from 'react'

type ToastLayout = {
  marginLeft: number
}

// 外部からトーストの左余白を調整できるようにする
// （サイドメニュー分の余白を挿入するのに用いる）
type ToastLayoutContextValue = {
  layout: ToastLayout
  setLayout: (layout: ToastLayout) => void
}

const ToastLayoutContext = createContext<ToastLayoutContextValue>({
  layout: {
    marginLeft: 0,
  },
  setLayout: () => {},
})

export const ToastLayoutProvider: FC<{}> = ({ children }) => {
  const [layout, setLayout] = useState<ToastLayout>({ marginLeft: 0 })
  const value = useMemo(
    () => ({
      layout,
      setLayout,
    }),
    [layout, setLayout],
  )

  return <ToastLayoutContext.Provider value={value}>{children}</ToastLayoutContext.Provider>
}

export const useToastLayout = () => useContext(ToastLayoutContext)
