import { ChakraProvider } from '@chakra-ui/react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastLayoutProvider } from 'src/feedback/ToastLayoutProvider'
import '../../styles/globals.css'
import { AppApolloProvider } from '../apollo/provider'
import { theme } from '../theme'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <AppHead />
      <AppApolloProvider>
        <ToastLayoutProvider>
          <Component {...pageProps} />
        </ToastLayoutProvider>
      </AppApolloProvider>
    </ChakraProvider>
  )
}

const AppHead = () => {
  return (
    <Head>
      <title>クロン医療機関設定</title>
    </Head>
  )
}

export default MyApp
