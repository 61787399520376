import { extendTheme } from '@chakra-ui/react'

// TODO
export const theme = extendTheme({
  colors: {
    blue: {
      50: '#EBF5FA',
      200: '#B4DAED',
      500: '#2097E5',
      800: '#046CC2',
    },
    yellow: {
      50: '#FFFAEB',
      200: '#F7E5BC',
      500: '#E5A020',
      800: '#C27502',
    },
    red: {
      50: '#FFF3F2',
      200: '#FFBBB5',
      600: '#E33B24',
      800: '#C92208',
    },
    green: {
      50: '#F0FAF5',
      200: '#A7D1BB',
      400: '#63BA8E',
      600: '#2BA168',
      800: '#157A4B',
    },
    gray: {
      50: '#F4F7FA',
      200: '#D3DDE8',
      400: '#93A3B5',
      600: '#637182',
      900: '#272C33',
    },
    social: {
      line: {
        500: '#4CC764',
      },
    },
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '28px',
    '5xl': '32px',
    '6xl': '40px',
    '7xl': '48px',
    '8xl': '56px',
    '9xl': '64px',
  },
  components: {
    Radio: {
      parts: ['label', 'control'],
      baseStyle: {
        control: {
          _checked: {
            border: '1px solid',
            borderColor: 'green.500',
            color: 'green.500',
            background: 'white',
            _hover: {
              border: '1px solid',
              borderColor: 'green.500',
              color: 'green.500',
              background: 'white',
            },
          },
        },
      },
      sizes: {
        sm: {
          control: {
            width: 4,
            height: 4,
          },
        },
        md: {
          control: {
            width: 5,
            height: 5,
          },
        },
      },
      defaultProps: {
        colorScheme: 'green',
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          border: '1px solid',
          borderColor: 'green.500',
        },
      },
      sizes: {
        md: {
          control: {
            width: 5,
            height: 5,
            borderRadius: '4px',
          },
        },
      },
      defaultProps: {
        colorScheme: 'green',
      },
    },
    Input: {
      baseStyle: {
        field: {
          boxShadow: 'none !important',
          _focus: {},
          _invalid: {
            backgroundColor: 'red.50',
            _hover: {
              borderColor: 'red.500',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            borderRadius: '4px',
            height: '40px',
          },
        },
        lg: {
          field: {
            borderRadius: '4px',
            height: '48px',
          },
        },
      },
      defaultProps: {
        borderRadius: '4px',
        focusBorderColor: 'gray.300',
      },
    },
    PinInput: {
      sizes: {
        md: {
          w: '50px',
          h: '64px',
          fontSize: '18px',
        },
      },
    },
    Button: {
      sizes: {
        sm: {
          fontSize: 'sm',
          height: '32px',
          borderRadius: '4px',
        },
        md: {
          fontSize: 'md',
          height: '40px',
          borderRadius: '4px',
        },
        lg: {
          fontSize: 'lg',
          height: '48px',
          borderRadius: '8px',
        },
      },
      variants: {
        solid: {
          _hover: {
            backgroundColor: 'green.800',
            _disabled: {
              backgroundColor: 'gray.50',
              color: 'gray.300',
            },
          },
          _focus: {
            boxShadow: 'none',
            outline: '2px solid #0077C7',
            outlineOffset: '2px',
          },
          _disabled: {
            opacity: 1,
            backgroundColor: 'gray.50',
            color: 'gray.300',
          },
        },
        outline: {
          _hover: {
            backgroundColor: 'gray.50',
          },
          _focus: {
            boxShadow: 'none',
            outline: '2px solid #0077C7',
            outlineOffset: '2px',
          },
          _disabled: {
            opacity: 0.5,
            backgroundColor: 'gray.50',
          },
        },
        ghost: {
          fontWeight: 'normal',
          _hover: {
            background: 'white',
          },
        },
        link: {
          fontWeight: 'normal',
        },
      },
      defaultProps: {
        colorScheme: 'green',
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'normal',
      },
    },
    // Toast 用のカスタマイズ
    Alert: {
      variants: {
        solid: (props: any) =>
          props.status !== 'error'
            ? {
                container: {
                  bg: 'rgba(49, 53, 65, 0.8)',
                },
              }
            : {
                container: {
                  bg: 'rgba(216, 30, 0, 0.75)',
                },
              },
      },
    },
  },
})
